<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-overlay :show="showLoading">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <ValidationProvider name="Organization" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="assignDesignation.org_id"
                            :options="orgList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="authOrgId > 0"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Office Type" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="office_type_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('org_pro.office_type')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="assignDesignation.office_type_id"
                            :options="officeTypeList"
                            id="office_type_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Office Name">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="office_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('org_pro.office_namel')}} <span class="text-danger">*</span>
                          </template>
                          <v-select name="office_id"
                            v-model="assignDesignation.office_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= officeList
                            :placeholder="$t('globalTrans.select')"
                            :filter-by="myFilter"
                            :state="errors[0] ? false : (valid ? true : null)"
                          />
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Designation" vid='designation_id' rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="designation_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('organogram.designationl')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="assignDesignation.designation_id"
                            :options="designationList"
                            id="designation_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="designation_count">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="designation_count"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('organogram.total_post')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            disabled
                            v-model="assignDesignation.designation_count"
                            id="designation_count"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="available_post">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="available_post"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('organogram.available_post')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            disabled
                            v-model="available_post"
                            id="available_post"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Approved post" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="approved_post"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                              {{$t('organogram.approved_post')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            type="number"
                            min='0'
                            id="office_name"
                            v-model="assignDesignation.approved_post"
                            @input="changeAvailablePost()"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <p class="text-danger text-right">{{ errorMsg }}</p>
                      <ValidationProvider name="Shorting Order" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="sorting_order"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                              {{$t('organogram.sorting_order')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            type="number"
                            id="sorting_order"
                            v-model="assignDesignation.sorting_order"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </b-overlay>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { assignDesignationStore, assignDesignationUpdate, assignDesignationListAll } from '../../api/routes'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getAssignDesignationData()
      this.assignDesignation = tmp
    } else {
      this.getLastSortingOrder()
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.authOrgId = this.$store.state.Auth.authUser.org_id
      this.assignDesignation = Object.assign({}, this.assignDesignation, {
        org_id: this.authOrgId
      })
    }
  },
  data () {
    return {
      errorMsg: '',
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      available_post: 0,
      available_post_all: 0,
      availableSum: 0,
      showLoading: false,
      assignDesignation: {
        org_id: '0',
        office_type_id: '0',
        office_id: null,
        designation_id: '0',
        approved_post: '',
        sorting_order: '',
        created_by: 1,
        updated_by: 1,
        designation_count: 0
      },
      officeList: [],
      officeTypeList: [],
      designationList: [],
      officeFilter: { orgId: '', officeTypeId: '' },
      authOrgId: 0,
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    'assignDesignation.org_id': function (newVal, oldVal) {
        this.officeTypeList = this.getOfficeTypeList(newVal)
        this.designationList = this.getDesignationList(newVal)
    },
    'assignDesignation.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeFilter = Object.assign({}, this.officeFilter, { officeTypeId: newVal })
        this.getFilteredOfficeList()
      }
    },
    'assignDesignation.designation_id': function (newVal, oldVal) {
      this.getDesignationListCount(newVal)
    }

  },
  mounted () {
    core.index()
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.assignDesignation = Object.assign({}, this.assignDesignation, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.assignDesignation = Object.assign({}, this.assignDesignation, {
        org_id: this.authUser.office_detail.org_id
      })
    }
  },
  methods: {
    changeAvailablePost () {
      this.available_post = this.available_post_all - this.assignDesignation.approved_post
    },
    getLastSortingOrder () {
      RestApi.getData(commonServiceBaseUrl, assignDesignationListAll).then(response => {
        if (response.success) {
          this.designationCheck = response.data.filter(item => item.sorting_order !== null)
          this.designationCheck.sort((a, b) => { return a.sorting_order - b.sorting_order })
          let lastOrder = 0
          this.designationCheck.forEach((element, key) => {
            lastOrder = element.sorting_order
          })
          this.assignDesignation.sorting_order = lastOrder + 1
        }
      })
    },
    getAssignDesignationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.showLoading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.assignDesignation.approved_post > this.available_post_all) {
        this.showLoading = false
        this.errorMsg = this.$t('organogram.errorMsg')
      } else {
        if (this.assignDesignation.id) {
          result = await RestApi.putData(commonServiceBaseUrl, `${assignDesignationUpdate}/${this.id}`, this.assignDesignation)
        } else {
          result = await RestApi.postData(commonServiceBaseUrl, assignDesignationStore, this.assignDesignation)
        }
      }
      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.showLoading = false
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.showLoading = false
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getOfficeTypeList (orgId) {
        const oficeTypeData = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === orgId)
        return oficeTypeData.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getDesignationList (orgId) {
      const designationListData = this.$store.state.commonObj.masterDesignationList.filter(item => item.status === 0 && item.org_id === orgId)
      return designationListData.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getDesignationListCount (designationId) {
      this.available_post_all = 0
      this.available_post = 0
      this.availableSum = 0
      const designationListData = this.$store.state.commonObj.designationList.find(item => item.value === designationId)
      this.assignDesignation.designation_count = designationListData.total_post
      const serachData = {
        designation_id: designationId,
        id: this.id
      }
      RestApi.getData(commonServiceBaseUrl, assignDesignationListAll, serachData).then(response => {
        if (response.success) {
          const datas = response.data.filter(item => item.designation_id === designationId)
          let availableSum = 0
          datas.map((itemData, key) => {
            availableSum += itemData.approved_post
          })
          this.available_post_all = parseInt(this.assignDesignation.designation_count) - availableSum
          this.available_post = parseInt(this.assignDesignation.designation_count) - availableSum
          if (this.id) {
            const tmp = this.getAssignDesignationData()
            this.available_post = this.available_post_all - tmp.approved_post
          }
        }
      })
    },
    getFilteredOfficeList () {
      const allOffices = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      this.officeList = allOffices.filter(office => {
        if (this.officeFilter.officeTypeId && this.officeFilter.orgId) {
          return office.office_type_id === this.officeFilter.officeTypeId && office.org_id === this.officeFilter.orgId
        } else if (this.officeFilter.officeTypeId) {
          return office.office_type_id === this.officeFilter.officeTypeId
        } else if (this.officeFilter.orgId) {
          return office.org_id === this.officeFilter.orgId
        } else {
          return true
        }
      })
    }
  }
}
</script>
