<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('organogram.assign_designation') }}</h4>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col xs="12" sm="12" md="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.organization')"
                    label-for="org_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="orgList"
                        id="org_id"
                        :disabled="authOrgId > 0"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.office_type')"
                    label-for="office_type_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.office_type_id"
                        :options="officeTypeList"
                        id="office_type_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.office_namel')"
                    label-for="office_id"
                    >
                    <v-select name="office_id"
                      v-model="search.office_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= officeList
                      :placeholder="$t('globalTrans.select')"
                      :filter-by="myFilter"
                    />
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('organogram.designationl')"
                    label-for="designation_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.designation_id"
                    :options="designationList"
                    id="designation_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="text-right">
              <b-button type="button" variant="primary" @click="searchData">
                <i class="fas fa-search"></i> {{ $t('globalTrans.search')}}
              </b-button>
            </div>
        </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('organogram.assign_designation') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button size="sm" v-show="searchButton" v-if="search.org_id" @click="pdfExport" class="mr-2">
              {{ $t('globalTrans.export_pdf') }}
            </b-button>
            <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered striped hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <!-- <div class="status_wrapper status_inactive" v-if="data.item.status" title="inactive">
                        <i class="fas fa-eye-slash"></i>
                      </div>
                      <div class="status_wrapper status_active" v-else title="active">
                        <i class="fas fa-eye"></i>
                      </div> -->
                      <b-badge pill variant="danger" v-if="data.item.status">{{ $t('globalTrans.inactive') }}</b-badge>
                      <b-badge pill variant="primary" v-else>{{ $t('globalTrans.active') }}</b-badge>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 variant=" iq-bg-success" size="sm" @click="edit(data.item)">
                        <i class="ri-ball-pen-fill"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action" title="Change Status" :class="data.item.status ? ' table_action_toggle' : ' table_action_status'" size="sm" @click="remove(data.item)">
                        <i class="fas" :class="data.item.status ? 'fa-toggle-off' : 'fa-toggle-on'"></i>
                      </a>
                      <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button :variant="data.item.status ? ' iq-bg-danger' : ' iq-bg-success'" size="sm" @click="remove(data.item)">
                          <i class="fas" :class="data.item.status ? 'fa-toggle-off' : 'fa-toggle-on'"></i>
                      </b-button> -->
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="$t('organogram.assign_designation')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form'
import { mapGetters } from 'vuex'
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { assignDesignationList, assignDesignationToggleStatus, assignDesignationListAll, officeDesignationsApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      searchButton: false,
      search: {
        office_id: null,
        designation_id: '0',
        office_type_id: '0',
        org_id: 0
      },
      officeList: [],
      officeTypeList: [],
      designationList: [],
      designationListAll: [],
      officeFilter: { orgId: '', officeTypeId: '' },
      authOrgId: 0,
      officeDesignations: [],
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('org_pro.office_namel'), class: 'text-center' },
          { label: this.$t('organogram.designationl'), class: 'text-center' },
          { label: this.$t('organogram.approved_post'), class: 'text-center' },
          { label: this.$t('organogram.sorting_order'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'office_name_bn' },
          { key: 'designation_bn' },
          { key: 'approved_post' },
          { key: 'sorting_order' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'office_name' },
          { key: 'designation' },
          { key: 'approved_post' },
          { key: 'sorting_order' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
        this.officeTypeList = this.getOfficeTypeList(newVal)
        this.designationList = this.getDesignationList(newVal)
    },
    'search.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeFilter = Object.assign({}, this.officeFilter, { officeTypeId: newVal })
        this.getFilteredOfficeList()
      }
    },
    'search.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setOfficeWiseDesignation(newVal)
      }
    }
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.authOrgId = this.authUser.org_id
      this.search = Object.assign({}, this.search, {
        org_id: this.authOrgId
      })
    }
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
    this.getOfficeDesignations()
  },
  methods: {
    getOfficeDesignations () {
      RestApi.getData(commonServiceBaseUrl, officeDesignationsApi, {}).then(response => {
        this.officeDesignations = response.data
      })
    },
    setOfficeWiseDesignation (officeId) {
      if (this.officeDesignations.length === 0) {
        this.designationList = []
        return
      }
      const filteredOfficeDesignations = []
      const designationList = this.$store.state.commonObj.designationList
      this.officeDesignations.forEach(element => {
        if (element.office_id === officeId) {
          const tmp = designationList.find(item => item.value === element.designation_id)
          filteredOfficeDesignations.push(tmp)
        }
      })
      this.designationList = filteredOfficeDesignations
    },
    searchData () {
      this.searchButton = true
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
      this.pdfLoadData()
    },
    remove (item) {
      this.changeStatus(commonServiceBaseUrl, assignDesignationToggleStatus, item)
    },
    pdfLoadData () {
        const params = Object.assign({}, this.search)
        RestApi.getData(commonServiceBaseUrl, assignDesignationListAll, params).then(response => {
            if (response.success) {
                this.designationListAll = response.data
            }
        })
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(commonServiceBaseUrl, assignDesignationList, params).then(response => {
        if (response.success) {
          const checkingData = response.data.data.sort((a, b) => { return a.sorting_order - b.sorting_order })
          this.$store.dispatch('setList', checkingData)
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getOfficeTypeList (orgId) {
        const oficeTypeData = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === orgId)
        return oficeTypeData.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getDesignationList (orgId) {
      const designationListData = this.$store.state.commonObj.designationList.filter(item => item.status === 0 && item.org_id === orgId)
      return designationListData.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getFilteredOfficeList () {
      const allOffices = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      this.officeList = allOffices.filter(office => {
        if (this.officeFilter.officeTypeId && this.officeFilter.orgId) {
          return office.office_type_id === this.officeFilter.officeTypeId && office.org_id === this.officeFilter.orgId
        } else if (this.officeFilter.officeTypeId) {
          return office.office_type_id === this.officeFilter.officeTypeId
        } else if (this.officeFilter.orgId) {
          return office.org_id === this.officeFilter.orgId
        } else {
          return true
        }
      })
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('org_pro.office_namel'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('organogram.designationl'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('organogram.approved_post'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
        if (this.$i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        this.designationListAll.map(item => {
            this.orgName = item.org_name
            this.orgNameBn = item.org_name_bn
        })
      var docDefinition = {
        content: [
          { text: this.$i18n.locale === 'en' ? this.orgName : this.orgNameBn, style: 'header1' },
          { text: this.$i18n.locale === 'en' ? 'Assign Designations list' : 'উপাধির নির্ধারণ তালিকা', style: 'header' },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ['15%', '30%', '30%', 'auto'],
              body: rowData
            }
          }
        ],
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture', color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            header: {
                alignment: 'center',
                margin: [5, 0, 5, 30],
                fontSize: 16,
                bold: true
            },
            header1: {
                alignment: 'center',
                margin: [5, 0, 5, 10],
                fontSize: 20,
                bold: true
            }
        }
      }
      pdfMake.createPdf(docDefinition, null).download()
    },
    getPdfData () {
        const keys = [
            { key: 'serial_no' },
            { key: this.$i18n.locale === 'en' ? 'office_name' : 'office_name_bn' },
            { key: this.$i18n.locale === 'en' ? 'designation' : 'designation_bn' },
            { key: 'approved_post' }
        ]
        var serial = 0
        const listData = this.designationListAll.map(item => {
            serial += 1
            const rowData = keys.map((keyItem, index) => {
                if (keyItem.key === 'serial_no') {
                    return { text: this.$n(serial) }
                }
                if (keyItem.key === 'approved_post') {
                    return { text: this.$n(item.approved_post) }
                }
            return { text: item[keyItem.key] }
            })

            return rowData
        })

      return listData
    }
  }
}
</script>
